/* https://raw.githubusercontent.com/kenwheeler/slick/master/slick/slick.css */
/* note that it's not exactly the above stylesheet & it has some edits */

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;

  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"),
    url("./fonts/slick.woff") format("woff"),
    url("./fonts/slick.ttf") format("truetype");
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  cursor: unset;
}

.slick-prev.slick-disabled > svg,
.slick-next.slick-disabled > svg {
  fill: transparent;
}

.slick-prev {
  left: -24px;
}
[dir="rtl"] .slick-prev {
  right: -24px;
  left: auto;
}

.slick-next {
  right: -24px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -24px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  display: flex !important;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-size: 3rem;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  color: var(--color-grey);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: var(--color-secondary);
}
