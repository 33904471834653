.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  margin-top: 2rem;
}
.input {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 1rem;
}
