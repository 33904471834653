/* General */
button {
  border: none;
  padding: 0.2rem 1rem;
  cursor: pointer;
}
button:focus {
  outline: none;
}
button:disabled {
  cursor: auto;
  opacity: 0.5;
}
/* Types */
button.primary {
  background-color: var(--color-primary);
  color: var(--color-light);
}
button.primary:hover {
  background-color: var(--color-primary-light);
}
button.primary:focus {
  background-color: var(--color-primary-dark);
}
button.secondary {
  background-color: var(--color-secondary);
  color: var(--color-light);
}
button.secondary:hover {
  background-color: var(--color-secondary-light);
}
button.secondary:focus {
  background-color: var(--color-secondary-dark);
}

button.cancel {
  background-color: var(--color-grey-light);
  color: var(--color-dark);
}
button.link {
  background-color: transparent;
  padding: 0;
}
button.light {
  background-color: var(--color-light);
  border: 1px solid var(--color-border);
  padding: 0;
}
button.chapter {
  border-radius: 50px;
  padding: 8px 16px;
  font-size: 1.4rem;
  line-height: 2.2rem;
  border: 1px solid var(--color-border);
  background-color: var(--color-light);
  color: var(--color-grey-dark);
  min-width: max-content;
  display: flex;
  align-items: center;
  box-sizing: content-box;
}
button.chapter.active {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  color: var(--color-light);
}

button.tip {
  background-color: var(--color-secondary);
  border-radius: 3px;
  color: var(--color-light);
  margin: 1rem auto;
  display: flex;
  font-weight: 700;
}
button.nav {
  z-index: 1;
  border-radius: 0;
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
/* Sizes */
button.sm {
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
}
button.md {
  font-size: 1rem;
  padding: 0.2rem 1rem;
}
button.lg {
  font-size: 1.6rem;
  padding: 1rem 1.5rem;
}
button.stretch {
  width: 100%;
}
/* Effects */
button.shutter-out-horizontal {
  position: relative;
  z-index: 1;
}
button.shutter-out-horizontal:before {
  border-radius: inherit;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-secondary-dark);
  position: absolute;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: 100%;
  transition: transform ease-in-out 0.5s;
}
button.shutter-out-horizontal:hover:before {
  transform: scaleX(1);
}

/* Shadow */
button.shadow {
  box-shadow: var(--shadow);
}
